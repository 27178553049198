import './StorieItem.scss'
import { useState } from 'react';
import {BsPlusLg} from 'react-icons/bs';
import getClassNames from '../../../../funcs/getClassNames';

const StorieItem = ({
    AllowedDeliveryTypes,
    ButtonActionItemID,
    ButtonText,
    ButtonTypeAction,
    Disabled,
    HiddenInOrganisations,
    HideInApp,
    ID,
    ItemOrder,
    images,
    PictureThumbnail,
    selectStorie,
    openStorie,
    startShow,
    startSelected,
    setStartSelected
}) => {
    const [tm, setTm] = useState(false)
    const [time, setTime] = useState()

    const clickHandle = () => {
        setTime(setTimeout(() => {
            setTm(true)
        }, 200))
    }

    const checkClick = () => {
        if(tm) {
            setTm(false)
            return;
        } else {
            clearTimeout(time)
            selectStorie({
                AllowedDeliveryTypes,
                ButtonActionItemID,
                ButtonText,
                ButtonTypeAction,
                Disabled,
                HiddenInOrganisations,
                HideInApp,
                ID,
                ItemOrder,
                images,
                PictureThumbnail
            })
            openStorie()
        }
    }

    return (
        <div className={getClassNames(['StorieItem', startSelected?.ID == ID && 'selected'])}>
            <div className="StorieItem__main" onMouseUp={checkClick} onMouseDown={clickHandle}>
                <div className="StorieItem__img">
                    <img src={images[0]?.PictureThumbnail} alt="" />
                </div>
            </div>
            {
                startShow && startSelected?.ID != ID ? (
                    <button onClick={() => {
                        if(startSelected?.ID == ID) {
                            setStartSelected(null)
                        } else {
                            setStartSelected({
                                AllowedDeliveryTypes,
                                ButtonActionItemID,
                                ButtonText,
                                ButtonTypeAction,
                                Disabled,
                                HiddenInOrganisations,
                                HideInApp,
                                ID,
                                ItemOrder,
                                images
                            })
                        }
                    }} 
                    className={"StorieItem__add" + (startSelected?.ID == ID ? ' StorieItem__add-danger ' : '')}>
                        <BsPlusLg/>
                    </button>
                ) : null
            }
        </div>
    )
}

export default StorieItem;