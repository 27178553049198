import Button from '../../../../components/Button/Button';
import getClassNames from '../../../../funcs/getClassNames';
import styles from './StorieModal.module.scss';
import { Modal, Row, Col } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import useStorieEditor from './storieEditor';
import { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Grad from './components/Grad/Grad';
import AddText from './components/AddText/AddText';
import TextPart from './components/TextPart/TextPart';
import Draggable from 'react-free-draggable';


const StorieModal = ({
  data,
  onCancel,
  onSaveStorie,
  ...otherProps
}) => {

  const editor = useStorieEditor()
  const [file, setFile] = useState(null)
  const [localData, setLocalData] = useState(null)

  useEffect(() => {
    if (data) {
      setLocalData(data)

      editor.initEditor({
        media: {
          type: data?.media?.type,
          source: data?.media?.source,
          file: data?.media?.file
        },
        grad: data?.grad,
        textList: data?.textList
      })
    } else {
      editor.initEditor({
        media: null,
        textList: [],
        grad: {value: 0, label: '0%'},
      })
    }
  }, [data])

  const onClose = () => {
    onCancel && onCancel()
    setFile(null)
    setLocalData(null)
    editor.onReset()
  }

  const onMediaUpload = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if(file) {
      if(file?.type?.includes('image')) {
        editor.initEditor({
          media: {type: 'image', source: URL.createObjectURL(file), file},
          textList: [],
          grad: {value: 0, label: '0%'},
        })
      }
      if(file?.type?.includes('video')) {
        //video
      }
    }
  }, [file])

  
  return (
    <Modal
      {...otherProps}
      onCancel={onClose}
      width={880}
      className={getClassNames(['Modal', styles.wrapper])}
    >
      <Row gutter={[25, 25]}>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col span={12}>
              <div onClick={() => onClose()} className={styles.close}>
                <BsArrowLeft />Сториз
              </div>
            </Col>
            <Col span={12}>
              <div className={styles.action}>
                <Button
                  onClick={() => {
                    if(data?.index >= 0) {
                      console.log(editor?.textList[0])
                      onSaveStorie({
                        media: {...editor?.media},
                        textList: editor.textList,
                        grad: editor.grad
                      }, data?.index)
                    } else {
                      onSaveStorie({
                        media: {
                          type: 'image',
                          source: file ? URL.createObjectURL(file) : editor?.media?.source,
                          file
                        },
                        textList: editor.textList,
                        grad: editor.grad
                      })
                    }
                    onClose()
                  }}
                  text={'Сохранить'}
                  styles={{ padding: '12px 35px', borderRadius: 20 }}
                  disabled={!file && !editor?.media?.source}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[25, 25]}>
            <Col span={12}>
              <div className={styles.field}>
                {
                  editor?.media ? (
                    <div 
                      className={getClassNames([styles.img, 'drag-bound'])}>
                      <div style={{backgroundColor: `rgba(0,0,0, .${editor?.grad?.value}`}} className={styles.mask}></div>
                      {
                        editor?.textList?.map((i,ind) => (
                          <Draggable
                            bounds={'.drag-bound'}
                            scale={1}
                            positionOffset={{
                              x: i?.coords[0],
                              y: i?.coords[1]
                            }}
                            onMove={(_,v) => {
                              editor?.editText({
                                data: {
                                  coords: [v.x, v.y]
                                },
                                itemIndex: ind
                              })
                            }}
                            >
                            <div
                            style={{
                              color: i?.color,
                              fontSize: i?.fontSize,
                              left: 0,
                              top: 0
                            }}
                            className={styles.layer}
                            >{i?.value}</div>
                          </Draggable>
                          
                        ))
                      }
                      {
                        editor?.media?.type === 'image' && (
                            <img src={editor?.media?.source} alt="" />
                          
                        )
                      }
                      {
                        editor?.media?.type === 'video' && (
                          {
                            // video
                          }
                        )
                      }
                    </div>
                  ) : (
                    <div className={styles.upload}>
                      <input
                        id='upload-storie'
                        type="file"
                        accept='.jpeg, .jpg, .png, .mp4'
                        onChange={onMediaUpload}
                        value={''}
                      />
                      <label htmlFor="upload-storie">
                        <span className={styles.icon}>
                          <AiOutlinePlus />
                        </span>
                        <span className={styles.label}>
                          Добавьте фото или видео
                        </span>
                      </label>
                    </div>
                  )
                }

                {/* text list */}

              </div>
            </Col>
            {
              editor?.media && (
                <Col span={12}>
                  <div className={styles.edit}>
                    <Row gutter={[20, 20]}>
                      <Col span={24}>
                        <Grad
                          editor={editor}
                        />
                      </Col>
                      <Col span={24}>
                        <AddText
                          onClick={() => {
                            editor?.addText({
                              value: 'Текст',
                              title: `Текст #${editor?.textList?.length + 1}`,
                              fontSize: 16,
                              color: '#fff',
                              coords: [0,0]
                            })
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        {
                          editor?.textList?.length > 0 && (
                            <Row 
                              style={{
                                maxHeight: 500,
                                overflowY: 'auto'
                              }}
                              gutter={[12, 12]}>
                              {
                                editor?.textList?.map((i, index) => (
                                  <Col key={index} span={24}>
                                    <TextPart
                                      data={i}
                                      itemIndex={index}
                                      editor={editor}
                                    />
                                  </Col>
                                ))
                              }
                            </Row>
                          )
                        }
                        {/* <Row gutter={[12, 12]}>
                          <Col span={24}>
                            <TextPart />
                          </Col>
                        </Row> */}
                      </Col>
                    </Row>
                  </div>
                </Col>
              )
            }

          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default StorieModal;